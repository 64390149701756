import { Controller } from '@hotwired/stimulus'
import Rails from 'rails-ujs'
import { debounce } from 'underscore'

export default class Autosave extends Controller {
  connect() {
    this.inputChanged = debounce(this.inputChanged.bind(this), 900)

    setTimeout(() => {
      this.element.querySelectorAll('input,textarea,select').forEach(element => {
        element.addEventListener('change', this.inputChanged)
        element.addEventListener('input', this.inputChanged)
        window.addEventListener('react-date-time:changed', this.inputChanged)
      })
      console.log('SuperForm: auto-save enabled.')
    }, 900)
  }

  disconnect() {
    this.element.querySelectorAll('input,textarea,select').forEach(element => {
      element.removeEventListener('change', this.inputChanged)
      element.removeEventListener('input', this.inputChanged)
      window.removeEventListener('react-date-time:changed', this.inputChanged)
    })
  }

  inputChanged(event) {
    Rails.ajax({
      url: this.element.action,
      type: 'PATCH',
      dataType: 'json',
      data: Rails.serializeElement(event.target),
      error: () => {
        console.log('SuperForm: auto-save failed.')
      }
    })
  }
}

export { Autosave }
