import { Controller } from '@hotwired/stimulus'
import { Dropdown } from 'bootstrap'

export default class DropdownButtonController extends Controller {
  static targets = ['toggle']

  connect() {
    this.dropdown = new Dropdown(this.toggleTarget)
    if (this.dropdown._menu === undefined)
      this.dropdown._menu = this.menuTarget
    this.toggleTarget.addEventListener('keydown', this.registerKeydown.bind(this))
    this.toggleTarget.addEventListener('show.bs.dropdown', this.onShow.bind(this))
  }

  onShow(event) {
    if (this.keyboardEvent && this.dropdown._menu) {
      this.dropdown._links = this.dropdown._menu.querySelectorAll('a')
      setTimeout(() =>this.dropdown._links[0].focus());
    }
  }

  registerKeydown(event) {
    if (event.keyCode == 13 || event.keyCode == 32) {
      this.keyboardEvent = true
    }
  }
}
