import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    currentUser: String,
    organization: String
  }

  connect() {
    this.loadHeap = this.loadHeap.bind(this)
    document.addEventListener('turbo:load', this.loadHeap)
  }

  disconnect() {
    document.removeEventListener('turbo:load', this.loadHeap)
  }

  loadHeap() {
    if(this.currentUserValue) {
      heap.identify(this.currentUserValue);
    }
    heap.addUserProperties({ organization: this.organizationValue });
  }
}
