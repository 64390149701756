import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    data: String
  }

  copy(event) {
    event.preventDefault()

    try {
      navigator.clipboard.writeText(this.dataValue)
      document.dispatchEvent(new CustomEvent('toast', { detail: { message: 'Copied to clipboard' } }))
    } catch (err) {
      console.error('Clipboard only works in HTTPS. HTTPS Support is in Puma in development mode.', err)
    }
  }
}
