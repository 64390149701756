import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    target: String
  }

  connect() {
    let targetFieldSet = document.getElementById(this.targetValue)?.closest('.fieldset')
    if (targetFieldSet && targetFieldSet.querySelectorAll('.is-invalid').length > 0) {
      this.element.querySelector('.alert-icon').classList.remove('d-none')
    }
  }
}
