import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['checkbox', 'hidden']

  connect() {
    this.checkHiddenField()
  }

  toggle(event) {
    if (event.target.checked) {
      this.uncheckHiddenField()
      this.checkboxTargets.forEach((checkbox) => {
        if (checkbox != event.target) {
          checkbox.checked = false;
        }
      });
    } else {
      this.checkHiddenField()
    }
    event.target.dispatchEvent(new Event('exclusive-checkbox:toggle', { bubbles: true }))
  }

  uncheckHiddenField() {
    if (this.hasHiddenTarget) {
      this.hiddenTarget.checked = false;
    }
  }

  checkHiddenField() {
    if (this.hasHiddenTarget) {
      var anyCheckBoxSelected = this.checkboxTargets.some(checkbox => checkbox.checked)
      if (!anyCheckBoxSelected) {
        this.hiddenTarget.checked = true;
      }
    }
  }
}
