import { Controller } from '@hotwired/stimulus';
import { Toast } from 'bootstrap';

export default class extends Controller {
  connect() {
    const trigger = this.element;
    const element = document.querySelector('.toast');

    if (element && trigger) {
      this.toast = new Toast(element);
      trigger.addEventListener('click', this.onClick.bind(this))
    }
  }

  onClick(event) {
    event.preventDefault()
    var tempInput = document.createElement('input')
    tempInput.value = event.target.dataset.text || event.target.innerText;
    document.body.appendChild(tempInput)
    tempInput.select()
    document.execCommand('copy')
    document.body.removeChild(tempInput)

    this.toast.show();
    setTimeout(() => {
      this.toast.hide();
    }, 2000);
  }

  disconnect() {
    this.element.removeEventListener('click', event => this.onClick(event))
  }

}
