import { Controller } from '@hotwired/stimulus'
import { Modal } from 'bootstrap'

export default class extends Controller {
  connect() {
    document.addEventListener('turbo:before-fetch-request', this.closeModalBeforeFetch)
  }

  disconnect() {
    document.removeEventListener('turbo:before-fetch-request', this.closeModalBeforeFetch)
  }

  closeModalBeforeFetch(event) {
    let modalEl = document.getElementById('modal')

    if (modalEl === null || modalEl.style.display !== 'block') return

    if (event.detail.fetchOptions.method === 'GET' && event.detail.fetchOptions.headers.Accept.match('text/html')) {
      event.preventDefault()
      modalEl.addEventListener('hidden.bs.modal', event.detail.resume)
      Modal.getOrCreateInstance('#modal').hide()
    }
  }
}
