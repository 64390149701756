import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  initialize() {
    this.autoDismissSnackbar = this.autoDismissSnackbar.bind(this)
  }

  connect() {
    document.addEventListener('turbo:load', this.autoDismissSnackbar)
  }

  disconnect() {
    document.removeEventListener('turbo:load', this.autoDismissSnackbar)
  }

  autoDismissSnackbar() {
    let snackBarEl = this.element
    setTimeout(() => { snackBarEl.style.display = 'none' }, 9000)
  }
}
