import { Controller } from '@hotwired/stimulus'
import { turboRequest } from '../../helpers/turbo_helper'
import { closeModal } from 'controllers/shared/modals/close_modal_controller.js'

export default class extends Controller {
  static values = {
    selectPersonUrl: String,
    personAttributesPath: Array
  }

  useThisPerson(event) {
    const personId = event.target.dataset.personId

    var url = new URL(this.selectPersonUrlValue)
    var params = new URLSearchParams(url.search)
    params.append('person_id', personId)

    turboRequest(`${url.pathname}?${params}`)

    closeModal('#modal')
  }

  ignoreDuplicatePersonModalWarning() {
    closeModal('#modal')

    this.updateBypassDuplicatePersonDetectionHiddenField()
  }

  updateBypassDuplicatePersonDetectionHiddenField() {
    // Construct the name attribute
    var nameAttribute = `${this.personAttributesPathValue.join('[').concat(']')}[bypass_duplicate_person_detection]`

    var forms = document.querySelectorAll('form')
    var form = forms[forms.length - 1]
    var existingInput = form.querySelector(`input[name="${nameAttribute}"]`)

    if (!existingInput) {
      var hiddenInput = document.createElement('input')
      hiddenInput.setAttribute('type', 'hidden')
      hiddenInput.setAttribute('name', nameAttribute)
      hiddenInput.setAttribute('value', true)

      form.appendChild(hiddenInput)
    } else {
      existingInput.value = true
    }

    form.submit()
  }
}
