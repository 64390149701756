import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['input', 'decrementButton']

  increment() {
    const input = this.inputTarget
    const previousValue = parseInt(input.value)

    input.value = previousValue + 1

    if (previousValue === 1) {
      // Enable the decrement button since the value is no longer at the threshold.
      this.decrementButtonTarget.removeAttribute('disabled')
    }
  }

  decrement() {
    const input = this.inputTarget;
    const previousValue = parseInt(input.value)

    input.value = previousValue - 1

    if (previousValue - 1 <= 1) {
      // Disable the decrement button since the new value is at the threshold or below.
      this.decrementButtonTarget.setAttribute('disabled', 'disabled')
    }
  }
}
