import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['legacyBulkUploadButton', 'legacyBulkUploadButtonText']

  setReportedMonth(event) {
    var reportedMonth = event.target.value

    var url = new URL(this.legacyBulkUploadButtonTarget.href)
    var params = new URLSearchParams(url.search)
    params.set('reported_month', reportedMonth)

    url.search = params.toString()

    this.legacyBulkUploadButtonTarget.href = url.toString()
    this.legacyBulkUploadButtonTextTarget.innerText = `Legacy Template (${reportedMonth})`
  }
}
