import { Controller } from '@hotwired/stimulus'
import ReactRailsUJS from 'react_ujs'

export default class extends Controller {
  static targets = [
    'isRepeatedInput',
    'calendar',
    'repeatTypeInput',
    'repeatEveryField',
    'repeatEveryInput',
    'inputText',
    'scheduleType',
    'fixedScheduleWrapper',
    'mandatoryAttendance'
  ]

  connect() {
    ReactRailsUJS.mountComponents('.calendar')

    if (this.hasRepeatTypeInputTarget) {
      this.updateRepeatEveryInputText(this.repeatTypeInputTarget.value)
    }
    if (this.hasScheduleTypeTarget) {
      this.updateScheduleWrapper(this.scheduleTypeTarget.value)
    }
    if (this.hasIsRepeatedInputTarget) {
      this.updateRepeatTypeAppearance($(this.element).find("[id$='_is_repeated_true']").prop('checked'))
    }

    if (document.getElementById('visit_visit_type')) {
      this.shiftEnabled = this.hasShift()
    }
  }

  handleisRepeatedInputChange({ target }) {
    this.updateRepeatTypeAppearance(target.value === 'true')
  }

  updateRepeatTypeAppearance(isRepeated) {
    const el = $(this.element)
    el.find('.repeat-field').hide()

    if (isRepeated) {
      el.find('.not-custom-schedule').show()
      this.updateCalendarAppearance(this.repeatTypeInputTarget.value)
    } else {
      this.clearAllRepeatingValues()
    }
  }

  handleRepeatTypeInputChange({ target }) {
    this.updateCalendarAppearance(target.value)
    this.updateRepeatEveryInputText(this.repeatTypeInputTarget.value)
  }

  updateCalendarAppearance(repeatType) {
    const el = $(this.element)
    el.find('.repeat-field').hide()

    if (repeatType === 'other') {
      el.find('.custom-schedule').show()
      this.clearSomeRepeatingValues()
    } else {
      el.find('.not-custom-schedule').show()
    }
  }

  updateRepeatEveryInputText(value) {
    switch (value) {
      case 'weekly':
        this.inputTextTarget.textContent = 'week(s)'
        break
      case 'monthly':
        this.inputTextTarget.textContent = 'month(s)'
        break
      default:
        this.inputTextTarget.textContent = 'day(s)'
    }
  }

  onScheduleTypeChange({ target }) {
    this.updateScheduleWrapper(target.value)
  }

  updateScheduleWrapper(value) {
    const el = $(this.element)
    el.find('.schedule-wrapper').hide()

    if (value === 'flexible') {
      if (this.hasShift()) $("#visit_visit_type option[value='shift']").remove()
      el.find('.flexible').show()
      this.clearAllRepeatingValues()
      $(this.element).find("[id$='is_repeated_false']").trigger('click')
    } else {
      // We remove shift as a visit type option at line 87 because we don't support flexible-shift visits. If the schedule changes back to fixed,
      // then we only want to reinsert the shift option if it was present at first
      if (this.shiftEnabled) this.addShiftToOptions()
      el.find('.fixed').show()
    }
  }

  addShiftToOptions() {
    var shift = document.createElement('option')
    shift.value = 'shift'
    shift.text = 'Shift'
    document.getElementById('visit_visit_type').add(shift, null)
  }

  hasShift() {
    const visitType = document.getElementById('visit_visit_type')
    const options = Array.apply(null, visitType.options)

    return options.filter(option => option.value === 'shift').length === 1
  }

  clearSomeRepeatingValues() {
    this.clearRepeatUntil(null)

    this.repeatEveryInputTarget.value = ''
  }

  clearRepeatUntil(date) {
    window.dispatchEvent(new CustomEvent('react-date-time:force-change', { detail: { id: 'repeat_until', date } }))
  }

  clearAllRepeatingValues() {
    if (this.hasIsRepeatedInputTarget) {
      this.repeatTypeInputTarget.value = ''
      this.clearSomeRepeatingValues()
    }
    if (this.hasMandatoryAttendanceTarget) {
      this.mandatoryAttendanceTarget.checked = false
    }
  }
}
