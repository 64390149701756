import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    triggerValues: Array
  }

  static targets = [
    'hiddenWrapper',
    'input',
    'selectInput',
    'message'
  ]

  connect() {
    if (this.hasInputTarget) {
      this.manageCheckboxInputChange(this.inputTarget.checked)
    }
    if (this.hasSelectInputTarget) {
      this.manageSelectInputChange(this.selectInputTarget.value)
    }
  }

  handleSelectInputChange({ target }) {
    this.manageSelectInputChange(target.value)
  }

  manageSelectInputChange(input) {
    if (this.hasHiddenWrapperTarget){
      if (this.triggerValuesValue.map(String).includes(input)) {
        this.hiddenWrapperTarget.classList.remove('d-none')
      } else {
        this.hiddenWrapperTarget.classList.add('d-none')
      }
    }
  }

  handleCheckBoxInputChange({ target }) {
    this.manageCheckboxInputChange(target.checked)
  }

  manageCheckboxInputChange(input) {
    if (input === true) {
      this.hiddenWrapperTarget.classList.add('d-none')
      if (this.hasMessageTarget) {
        this.displayMessage(true)
      }
    }
    else {
      this.hiddenWrapperTarget.classList.remove('d-none')
      if (this.hasMessageTarget) {
        this.displayMessage(false)
      }
    }
  }

  displayMessage(input) {
    if (input === true) {
      this.messageTarget.classList.remove('d-none')
    }
    else {
      this.messageTarget.classList.add('d-none')
    }
  }
}
