/* eslint-disable no-undef */

import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect() {
    window.addEventListener('react-date-time:changed', this.dateChange, { once: true })
  }

  static values = {
    url: String
  }

  dateChange(event) {
    if (event.detail.id === 'dates-navigation') {
      const date = new Date(event.detail.date)
      const formattedDate = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`

      const url = `${window.location.pathname}?date=${formattedDate}`
      Turbo.visit(url)
    }
  }

  disconnect() {
    window.removeEventListener('react-date-time:changed', this.dateChange, { once: true })
  }
}
