import { Controller } from '@hotwired/stimulus'
import { Collapse } from 'bootstrap'
import { delay } from 'underscore'

export default class extends Controller {
  connect() {
    this.handleInput = this.handleInput.bind(this)
    this.updateVisibility = this.updateVisibility.bind(this)
    this.getDependentElements = this.getDependentElements.bind(this)

    let dependsOn = this.data.get('depends-on')
    this.dependsOnValues = this.data.get('depends-on-value').split('||')
    this.dependsOnEls = this.getDependentElements(document, dependsOn)
    this.dependsOnEls.forEach(el => el.addEventListener('input', this.handleInput))
    this.dependsOnEls.forEach(el => el.addEventListener('change', this.handleInput))
    this.dependsOnEls.forEach(el => el.addEventListener('select', this.handleInput))
    this.collapse = new Collapse(this.element)
    delay(this.updateVisibility, 600, this.dependsOnEls.map(el => el.value))
  }

  getDependentElements(document, dependsOn) {
    let elements = Array.from(document.getElementsByName(dependsOn))
    if (elements.length === 1) {
      return elements
    } else {
      let arrayElements = Array.from(document.getElementsByName(dependsOn + '[]').values())

      if (arrayElements.length === 0) { // for edge case of radio buttons
        arrayElements = Array.from(document.getElementsByName(dependsOn))
        arrayElements.forEach(element => element.addEventListener('change', this.handleInput))
      }

      return arrayElements.filter(element => this.dependsOnValues.includes(element.value.toString()))
    }
  }

  disconnect() {
    this.dependsOnEls.forEach(el => el.removeEventListener('input', this.handleInput))
    this.dependsOnEls.forEach(el => el.removeEventListener('change', this.handleInput))
    this.dependsOnEls.forEach(el => el.removeEventListener('select', this.handleInput))
  }

  handleInput(event) {
    this.updateVisibility([event.target.value.toString()])
  }

  updateVisibility(values) {
    if (
      (['checkbox', 'radio'].includes(this.dependsOnEls[0]?.type) && this.dependsOnEls.some(el => el.checked)) ||
      (!['checkbox', 'radio'].includes(this.dependsOnEls[0]?.type) &&
        this.dependsOnValues.some(val => values.includes(val)))
    ) {
      this.collapse.show()
      this.element.querySelectorAll('input, textarea, select').forEach(input => input.disabled = false)
    } else {
      this.collapse.hide()
      this.element.querySelectorAll('input, textarea, select').forEach(input => {
        input.disabled = true

        if (input.type === 'hidden') { return }

        if (['checkbox', 'radio'].includes(input.type)) {
          input.checked = false
        } else {
          input.value = null
        }
      })
    }
  }
}
