import { Controller } from '@hotwired/stimulus'
import { Tooltip } from 'bootstrap'

export default class extends Controller {
  static targets = ['tooltip']

  connect() {
    if (!this.hasTooltipTarget) { return }

    const tooltipElement = this.tooltipTarget

    if (tooltipElement.getAttribute('data-bs-title')) {
      const allowHtml = tooltipElement.dataset.html === 'true'
      const tooltip = new Tooltip(tooltipElement, { html: allowHtml })
    }
  }
}
