import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['sidenav']

  connect() {
    this.clickListener = this.clickListener.bind(this)
  }

  closeNav() {
    this.sidenavTarget.style.width = 0
    document.removeEventListener('click', this.clickListener, true)
  }

  openNav() {
    this.sidenavTarget.style.width = '60vw'
    document.addEventListener('click', this.clickListener, true)
  }

  clickListener (e) {
    var snav = document.getElementById('mySidenav')
    if (e.target.id !== snav.id && !snav.contains(e.target) && !e.target.className.match('open-nav')) {
      this.closeNav()
    }
  }
}
