import { Controller } from '@hotwired/stimulus'
import { turboFetchCollection } from '../../helpers/turbo_helper'

export default class extends Controller {
  static targets = [
    'recipientTypeFilter',
    'recipientValueInput'
  ]
  handleRecipientTypeFilterChange(event) {
    let inputValue = this.hasRecipientValueInputTarget ? this.recipientValueInputTarget.value : ''
    const filter = this.recipientTypeFilterTarget.value

    inputValue = this.clearInputValue(filter, inputValue)

    turboFetchCollection(
      '/admin/org/service_records/recipient_filter', '', {
        filter: filter,
        value: inputValue
      })
  }

  clearInputValue(filter, inputValue) {
    if (filter === 'Client') {
      return inputValue;
    }

    return '';
  }

  addFilterWidth() {
    document.getElementsByClassName('popover')[0].classList.add('max-width-fits-content')
    document.getElementsByClassName('popover-body')[0].classList.add('thirty-rem-width')
  }
}
