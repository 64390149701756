import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    message: String
  }

  connect() {
    if (this.messageValue) {
      document.dispatchEvent(new CustomEvent('toast', { detail: { message: this.messageValue } }))

      this.element.remove()
    }
  }
}
