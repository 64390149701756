import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['currentError', 'totalErrors', 'total']

  connect() {
    this.currentError = 0
    this.currentErrorTarget.innerText = this.currentError
    this.formBody = this.element.parentNode.querySelector('.superform-form-body')

    this.errorCount = this.formBody.querySelectorAll('.invalid-feedback').length
    this.totalTarget.innerText = this.errorCount
    this.totalErrorsTarget.innerText = `${this.errorCount} ${this.errorCount === 1 ? 'issue' : 'issues'} found.`
  }

  nextError() {
    let nextError = this.currentError + 1

    nextError > this.errorCount ? (this.currentError = 1) : (this.currentError = nextError)

    this.navigate()
  }

  previousError() {
    let previousError = this.currentError - 1

    previousError <= 0 ? (this.currentError = this.errorCount) : (this.currentError = previousError)

    this.navigate()
  }

  navigate() {
    this.currentErrorTarget.innerText = this.currentError

    let invalidFields = this.formBody.querySelectorAll('.invalid-feedback')

    if (invalidFields.length > 0) {
      let fieldWithError = invalidFields[this.currentError - 1]

      if (fieldWithError) {
        fieldWithError.parentElement.scrollIntoView({ behavior: 'smooth' })
      }
    }
  }
}
