import { Controller } from '@hotwired/stimulus'
import { Modal } from 'bootstrap'

export default class extends Controller {
  static targets = [
    'bulkUpdateButton',
    'serviceRecords',
    'bulkUpdateButtonError',
    'bulkDeleteButton',
    'bulkManagementActionsContainer',
    'bulkManagementActionsContainerSummary',
    'bulkManagementActionsContainerSummaryCount',
    'bulkManagementActionsContainerSummaryText',
    'exportSelectedButton',
    'exportButton'
  ]

  static values = {
    url: String,
    deleteUrl: String,
    exportModalUrl: String,
    exportUrl: String,
    currentPageRecords: Number,
    totalRecords: Number,
    siteRequired: Boolean
  }

  connect() {
    this.serviceRecordSelected = false
    if (this.hasBulkUpdateButtonTarget) {
      this.defaultBulkEditButtonLabel = this.bulkUpdateButtonTarget.text
    }
    if (this.hasBulkDeleteButtonTarget) {
      this.defaultBulkDeleteButtonLabel = this.bulkDeleteButtonTarget.text
    }
  }

  // invoked on service records index table header checkbox click
  toggleCheckBoxes() {
    this.serviceRecordSelected = !this.serviceRecordSelected
    let checkboxes = this.serviceRecordsTarget.querySelectorAll('input[type="checkbox"]')
    checkboxes.forEach(el => (el.checked = this.serviceRecordSelected))

    this.toggleBulkActionButtons()
  }

  // reveals or hides buttons to bulk edit / delete selected service records
  toggleBulkActionButtons() {
    const selectedServiceRecords = Array.from(
      this.serviceRecordsTarget.querySelectorAll('input.service-record-checkbox:checked')
    )

    if (selectedServiceRecords.length > 0) {
      const firstSelectedServiceRecordProgram = selectedServiceRecords[0].parentElement?.parentElement?.dataset?.program
      const allMatchingProgram = selectedServiceRecords.every(
        record => record.parentElement?.parentElement?.dataset?.program === firstSelectedServiceRecordProgram
      )

      const selectedCount = selectedServiceRecords.length
      const checkSites = this.siteAndGranularPermissionsCheck(selectedServiceRecords)

      if (allMatchingProgram && checkSites) {
        if (this.hasBulkUpdateButtonErrorTarget) {
          this.hide(this.bulkUpdateButtonErrorTarget)
        }
        if (this.hasBulkUpdateButtonTarget) {
          this.reveal(this.bulkUpdateButtonTarget)
          this.setSummaryCountandTextFor(selectedCount)
          this.reveal(this.bulkManagementActionsContainerSummaryTarget)
        }
      } else {
        if (this.hasBulkUpdateButtonTarget) {
          this.hide(this.bulkUpdateButtonTarget)
        }
        if (this.hasBulkUpdateButtonErrorTarget) {
          if (!checkSites && !allMatchingProgram) {
            this.bulkUpdateButtonErrorTarget.parentElement.setAttribute('data-bs-original-title', 'Filter your service records down to a single program and site to enable editing')
          } else if (!allMatchingProgram) {
            this.bulkUpdateButtonErrorTarget.parentElement.setAttribute('data-bs-original-title', 'Filter your service records down to a single program to enable editing')
          } else {
            this.bulkUpdateButtonErrorTarget.parentElement.setAttribute('data-bs-original-title', 'Filter your service records down to a single site to enable editing')
          }

          this.reveal(this.bulkUpdateButtonErrorTarget)
          this.setSummaryCountandTextFor(selectedCount)
          this.reveal(this.bulkManagementActionsContainerSummaryTarget)
        }
      }
      if (this.hasBulkDeleteButtonTarget) {
        this.reveal(this.bulkDeleteButtonTarget)
        this.setSummaryCountandTextFor(selectedCount)
      }

      if (this.hasBulkManagementActionsContainerTarget) {
        this.reveal(this.bulkManagementActionsContainerTarget)
      }
    } else {
      if (this.hasBulkUpdateButtonTarget) {
        this.hide(this.bulkUpdateButtonTarget)
      }

      if (this.hasBulkDeleteButtonTarget) {
        this.hide(this.bulkDeleteButtonTarget)
      }

      if (this.hasBulkUpdateButtonErrorTarget) {
        this.hide(this.bulkUpdateButtonErrorTarget)
      }

      if (this.hasBulkManagementActionsContainerTarget) {
        this.hide(this.bulkManagementActionsContainerTarget)
      }
    }
  }

  reveal(element) {
    element.classList.remove('d-none')
  }

  hide(element) {
    element.classList.add('d-none')
  }

  setSummaryCountandTextFor(selectedCount) {
    this.bulkManagementActionsContainerSummaryCountTarget.innerHTML = selectedCount
    this.bulkManagementActionsContainerSummaryTextTarget.innerHTML =
      `&nbsp;${selectedCount === 1 ? 'service record on this page is selected'
                                  : 'service records on this page are selected'}`
  }

  // sends request which will pop a modal for bulk updating fields on selected service records
  updateSelectedServiceRecords(event) {
    event.preventDefault()

    this.openModalWithSelectedServiceRecord(this.urlValue)
  }

  // sends request which will pop a modal for bulk deleting selected service records
  deleteSelectedServiceRecords(event) {
    event.preventDefault()

    this.openModalWithSelectedServiceRecord(this.deleteUrlValue)
  }

  exportServiceRecords(event) {
    event.preventDefault()

    this.openModalWithSelectedServiceRecord(this.exportModalUrlValue, false)
  }

  exportSelectedServiceRecords(event) {
    event.preventDefault()

    this.openModalWithSelectedServiceRecord(this.exportModalUrlValue)
  }

  openModalWithSelectedServiceRecord(modalUrl, selectionWarning = true) {
    let selectedServiceRecords = Array.from(
      this.serviceRecordsTarget.querySelectorAll('input.service-record-checkbox:checked')
    )
    let selectedServiceRecordsIds = selectedServiceRecords.map(record => parseInt(record.value))

    if (selectedServiceRecords.length > 0 || !selectionWarning) {
      var baseURL = new URL(modalUrl)
      var searchParams = new URLSearchParams(baseURL.search)

      if (selectedServiceRecords.length > 0) {
        selectedServiceRecordsIds.forEach(id => searchParams.append('ids[]', id))
        searchParams.append('selected_count', selectedServiceRecordsIds.length)
      }

      searchParams.append('current_page_count', this.currentPageRecordsValue)
      searchParams.append('total_count', this.totalRecordsValue)

      const url = baseURL.pathname + '?' + searchParams

      if (url.length >= 10240) {
        alert(`Too many service records selected.`)
      } else {
        document.getElementById('modal').src = url
      }
    } else {
      document.dispatchEvent(
        new CustomEvent('toast', { detail: { failure: true, message: 'No service records selected' } })
      )
    }
  }

  export(event) {
    event.preventDefault()

    let exportTypeRadioButton = document.querySelector('input[name="export_type"]:checked')
    let exportType = exportTypeRadioButton ? exportTypeRadioButton.value : null

    if (exportType) {
      var baseURL = new URL(this.exportUrlValue)
      var searchParams = new URLSearchParams(baseURL.search)

      searchParams.append('export_type', exportType)

      this.downloadCSV(baseURL, searchParams)
    } else {
      alert('Please specify which rows you want to export.')
    }
  }

  downloadCSV(baseURL, searchParams) {
    const url = baseURL.pathname + '?' + searchParams

    let csvDownloadLink = document.getElementById('download-csv-link')
    csvDownloadLink.href = url
    csvDownloadLink.click()

    Modal.getOrCreateInstance('#modal').hide()
  }

  siteAndGranularPermissionsCheck(selectedServiceRecords) {
    if (!this.siteRequiredValue) {
      return true
    }

    const firstSelectedServiceRecordSite = selectedServiceRecords[0].parentElement?.parentElement?.dataset?.site
    return selectedServiceRecords.every(
      record => record.parentElement?.parentElement?.dataset?.site === firstSelectedServiceRecordSite
    )
  }
}
