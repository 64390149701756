import { Controller } from '@hotwired/stimulus'

export default class extends Controller {

  initialize() {
    this.element.setAttribute('loading', 'lazy')
  }

  connect() {
    this.rehydrate = this.rehydrate.bind(this)
    document.addEventListener('turbo:load', this.rehydrate)
  }

  disconnect() {
    document.removeEventListener('turbo:load', this.rehydrate)
  }

  rehydrate(event) {
    this.element.reload()
  }
}
