import { Controller } from '@hotwired/stimulus'
import { Modal } from 'bootstrap'

export default class extends Controller {
  static values = {
    domId: String
  }

  connect() {
    closeModal(this.domIdValue, this.element)
  }
}

export function closeModal(domId, element = null) {
  const modalEl = document.querySelector(domId)

  if (modalEl) {
    const modal = Modal.getOrCreateInstance(domId)
    modal.hide()
    if (modal._backdrop) {
      modal.dispose()
    }

    // The template element is hidden by default anyway but it's nicer to remove it (since it will also disconnect the controller)
    //
    if (element) {
      element.remove()
    }
  }
}
