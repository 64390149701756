import { Controller } from '@hotwired/stimulus'
import ReactRailsUJS from 'react_ujs'

export default class extends Controller {
  connect() {
    let targetElementId = this.element.dataset.targetElementId

    let targetElement = null
    if (targetElementId) { targetElement = document.getElementById(targetElementId) }

    targetElement ? ReactRailsUJS.mountComponents(targetElement) : ReactRailsUJS.mountComponents()
    this.element.remove()
  }
}
