import { Controller } from '@hotwired/stimulus'
import IMask from 'imask'

export default class extends Controller {
  connect() {
    var maskOptions = {
      mask: '+{1} (000) 000-0000',
      lazy: !this.element.value //Use to show mask when the component loads with data in it. Usually when an error occurs
    }

    var patternMask = IMask(this.element, maskOptions)

    //when you click on input, placeholders are shown
    this.element.addEventListener(
      'focus',
      function () {
        patternMask.updateOptions({ lazy: false })
      },
      true
    )
    //when click off, hide placeholders if input is empty
    this.element.addEventListener('blur', function () {
      if (patternMask.unmaskedValue.length === 1) {
        patternMask.updateOptions({ lazy: true })
        patternMask.value = ''
      }
    })
  }
}
