import { Controller } from '@hotwired/stimulus'
import Rails from 'rails-ujs'

export default class extends Controller {
  static values = {
    url: String
  }

  generate(event) {
    event.preventDefault()
    console.log('Generating summary...', this.urlValue)

    let summaryEl = this.element.querySelector('textarea')

    if (
      summaryEl.value.length === 0 ||
      (summaryEl.value.length > 0 &&
        window.confirm(
          "Taking this action overwrites anything you've already written in this field. You will lose what’s currently here and this cannot be undone. Are you sure you want to generate a new summary?"
        ))
    ) {
      Rails.ajax({
        url: this.urlValue,
        type: 'POST',
        dataType: 'json',
        success: response => {
          summaryEl.value = response.summary
        },
        error: () => {
          document.dispatchEvent(
            new CustomEvent('toast', {
              detail: { message: 'Document must be completed before a summary can be generated.' }
            })
          )
        }
      })
    }
  }
}
