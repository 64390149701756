import { Controller } from '@hotwired/stimulus'
import snackbarCheckImg from 'images/admin/org/snackbar-check.svg'
import { Toast } from 'bootstrap'

export default class extends Controller {
  connect() {
    this.handleToastMessage = this.handleToastMessage.bind(this)
    document.addEventListener('toast', this.handleToastMessage)
  }

  disconnect() {
    document.removeEventListener('toast', this.handleToastMessage)
  }

  handleToastMessage(event) {
    let toastTemplate = `
    <div class="toast hide" data-delay="5000">
      <div class="d-flex">
        ${this.handleToastImg(event)}
        <div class="ms-2">
          ${event.detail.message}
        </div>
      </div>
    </div>
    `
    document.querySelectorAll('.toast').forEach(el => el.remove())
    document.body.insertAdjacentHTML('beforeend', toastTemplate)
    const toastEl = document.querySelector('.toast')
    const toast = new Toast(toastEl)
    toast.show()
  }

  handleToastImg(event) {
    return event.detail.failure ? '' : `<img src="${snackbarCheckImg}"></img>`
  }
}
