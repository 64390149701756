import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  initialize() {
    document.querySelectorAll('a[data-turbo-frame="modal"], a[data-remote="true"]').forEach(element => {
      element.addEventListener('click', this.preventNewTab);
    })

    document.addEventListener('turbo:before-stream-render', event => {
      if (event.target.action === 'update') {
        setTimeout(() => {
          document.querySelectorAll('a[data-turbo-frame="modal"], a[data-remote="true"]').forEach(element => {
            element.addEventListener('click', this.preventNewTab)
          })
        }, 100)
      }
    })
  }

  disconnect() {
    document.querySelectorAll('a[data-turbo-frame="modal"], a[data-remote="true"]').forEach(element => {
      element.removeEventListener('click', this.preventNewTab);
    })
  }

  preventNewTab(event) {
    if (event.metaKey || event.ctrlKey) {
      document.dispatchEvent(new CustomEvent('toast', { detail: { message: 'Cannot open this link in a new tab' }}))
      event.preventDefault();
    }
  }
}
