import { turboRequest } from '../../../helpers/turbo_helper'
import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect() {
    this.filter = this.filter.bind(this)
    this.servicesChanged = this.servicesChanged.bind(this)
    this.reloadExportOptions = this.reloadExportOptions.bind(this)

    addEventListener('input:change', this.servicesChanged)
    addEventListener('turbo:before-frame-render', this.reloadExportOptions)
  }

  disconnect() {
    removeEventListener('input:change', this.servicesChanged)
    removeEventListener('turbo:before-frame-render', this.reloadExportOptions)
  }

  servicesChanged(event) {
    if (event.detail.name !== 'service_definition_ids') {
      return
    }
    this.filter()
  }

  filter() {
    this.element.requestSubmit()
  }

  reloadExportOptions() {
    turboRequest(`members/export_settings${window.location.search}`)
    turboRequest(`members/last_active_on_options${window.location.search}`)
    turboRequest(`members/export_smart_list${window.location.search}`)
  }
}
