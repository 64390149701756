import { Controller } from '@hotwired/stimulus'
import Popover from 'bootstrap/js/dist/popover'

export default class extends Controller {
  connect() {
    this.popover = new Popover(this.element)
  }

  clipboardCopyTo(event) {
    event.preventDefault()
    var tempInput = document.createElement('input')
    tempInput.value = event.params.text
    document.body.appendChild(tempInput)
    tempInput.select()
    document.execCommand('copy')
    document.body.removeChild(tempInput)

    this.popover.show()
    setTimeout(() => {
      this.popover.hide()
    }, 2000)
  }
}
