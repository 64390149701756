import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    visibilityControl: String
  }

  static targets = [
    'hiddenWrapper',
    'radioInput'
  ]

  connect() {
    this.toggleHiddenWrapperOnCheckedRadio()
  }

  toggleHiddenWrapperOnCheckedRadio() {
    const checkedRadio = this.radioInputTargets.find(radio => radio.checked)
    if (checkedRadio) {
      this.toggleHiddenWrapper(checkedRadio.value)
    }
  }

  handleRadioInputChange({ target }) {
    this.toggleHiddenWrapper(target.value)
  }

  toggleHiddenWrapper(value) {
    const shouldShow = value === this.visibilityControlValue

    if (this.hasHiddenWrapperTarget) {
      if (shouldShow) {
        this.hiddenWrapperTarget.classList.remove('d-none')
      } else {
        this.hiddenWrapperTarget.classList.add('d-none')
      }
    }
  }
}
