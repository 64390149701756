import { Controller } from '@hotwired/stimulus'
import { Dropdown } from 'bootstrap'

export default class ClientPreviewController extends Controller {
  static targets = ['toggle', 'menu']

  connect() {
    this.dropdown = new Dropdown(this.toggleTarget)
    if (this.dropdown._menu === undefined)
      this.dropdown._menu = this.menuTarget
    this.toggleTarget.addEventListener('mouseover', this.onMouseover.bind(this))
    this.toggleTarget.addEventListener('mouseleave', this.onMouseleave.bind(this))
    this.menuTarget.addEventListener('mouseover', this.onMouseover.bind(this))
    this.menuTarget.addEventListener('mouseleave', this.onMouseleave.bind(this))
  }

  onMouseover(event) {
    setTimeout(() => {
      if (this.dropdown._menu.contains(event.target) || this.dropdown._element.contains(event.target)) {
        this.dropdown.show()
      }
    }, 250)
  }

  onMouseleave(event) {
    setTimeout(() => {
      if (!this.isHovering()) {
        this.dropdown.hide()
      }
    }, 250)
  }

  isHovering() {
    return this.dropdown._menu.matches(':hover') || this.dropdown._element.matches(':hover')
  }

  disconnect() {
    this.toggleTarget.removeEventListener('mouseover', event => this.onMouseover(event))
    this.toggleTarget.removeEventListener('mouseleave', event => this.onMouseleave(event))
    this.menuTarget.removeEventListener('mouseover', event => this.onMouseover(event))
    this.menuTarget.removeEventListener('mouseleave', event => this.onMouseleave(event))
  }
}
