import { Controller } from '@hotwired/stimulus'
import IMask from 'imask'

export default class extends Controller {
  connect() {
    this.mask = IMask(this.element, {
      mask: Number,
      scale: 2,
      thousandsSeparator: ',',
      padFractionalZeros: true,
      normalizeZeros: true,
      radix: '.',
      mapToRadix: ['.']
    })

    this.updateMask = this.updateMask.bind(this)
    this.element.addEventListener('change', this.updateMask)
  }

  disconnect() {
    this.element.removeEventListener('change', this.updateMask)
  }

  updateMask() {
    this.element.dispatchEvent(new Event('blur'))
  }
}
