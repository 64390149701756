import { Controller } from '@hotwired/stimulus'

const ARRAY_INPUT_NAME_REGEX = /\[\]/
const MULTIPLE_INPUT_ELEMENTS = ['checkbox', 'radio']
const TRUE_STRING = true.toString()
const FALSE_STRING = false.toString()

const NormalizedNumber = value => {
  return Number(value.toString().replaceAll(',', ''))
}

export default class extends Controller {
  connect() {
    this.updateListener = this.updateListener.bind(this)
    this.updateFormula = this.updateFormula.bind(this)
    this.evalListeners = this.evalListeners.bind(this)
    this.evalListener = this.evalListener.bind(this)

    this.formula = JSON.parse(this.data.get('formula'))
    this.formPrefix = this.data.get('form-prefix')
    this.element.setAttribute('tabindex', -1)
    this.elementCache = {}

    this.evalListeners(this.formula)
    this.updateFormula(this.formula)
  }

  disconnect() {
    this.evalListeners(this.formula, true)
  }

  /**
   * Setup or teardown event listeners by parsing the SuperForm formula expression.
   * @param {Array} expression - a SuperForm expression S-Expression.
   * @param {Boolean|Null} teardown - passing a not null value will remove event listeners.
   */
  evalListeners(expression, teardown) {
    if (Array.isArray(expression)) {
      let [operation, ...operands] = expression
      if (this.OPERATIONS[operation]) {
        operands.forEach(operand => this.evalListeners(operand, teardown))
      } else {
        this.evalListener(expression)
      }
    } else {
      this.evalListener(expression)
    }
  }

  /**
   * Setup or teardown a single event listener by parsing the SuperForm formula expression.
   * @param {Array} expression - a SuperForm expression S-Expression.
   * @param {Boolean|Null} teardown - passing a not null value will remove event listeners.
   */
  evalListener(expression, teardown) {
    let operands = Array.isArray(expression) ? expression : [expression]
    operands.forEach(operand => {
      var els = this.getElementsByName(operand)
      if (els.length) {
        if (teardown) {
          els.forEach(el => el.removeEventListener('input', this.updateListener))
          els.forEach(el => el.removeEventListener('change', this.updateListener))
        } else {
          els.forEach(el => el.addEventListener('input', this.updateListener))
          els.forEach(el => el.addEventListener('change', this.updateListener))
        }
      }
    })
  }

  /**
   * Event listener to trigger formula updates when dependent fields change.
   */
  updateListener() {
    this.updateFormula(this.formula)
  }

  /**
   * Update the element with the result of the formula and fire a change Event so dependent fields can be recalculated.
   *
   * @param {Array} formula - a SuperForm formula S-Expression
   */
  updateFormula(formula) {
    let result = this.evalFormula(formula)
    if (this.element.value !== result.toString()) {
      this.element.value = result
      this.element.dispatchEvent(new Event('change'))
    }
  }

  /**
   * Add operation definitions here! Every operation should return a list or a Numeric value.
   */
  OPERATIONS = {
    /**
     * Sum the values in the list.
     *
     * Examples:
     *    :sum, []
     *    :sum, [1, 2]
     *    :sum, ['field_1', 'field_2']
     *    :sum, [12, 'field_1', 'field_2']
     *
     * @param {Array<Numeric>} list - a list of Numeric values to sum
     * @returns {Numeric} the sum of the list
     */
    sum: list => {
      list = this.normalizeOperands(list)
      return list.reduce((a, b) => a + b, 0)
    },
    /**
     * Subract the first value from the last value.
     *
     * Examples:
     *    :sub, []      # => 0
     *    :sub, [3, 8]  # => 5
     *    :sub, ['field_1', 'field_2']
     *    :sub, [12, 'field_1']
     *
     * @param {Array<Numeric>} list - a pair of Numeric values [subtrahend, minuend]
     * @returns {Numeric} the difference of the subtrahend from the minuend
     */
    sub: list => {
      list = this.normalizeOperands(list)
      return list.reduce((a, b) => b - a, 0)
    },
    /**
     * Multiply the values in the list.
     *
     * Examples:
     *    :multiply, [5, 2]
     *    :multiply, ['field_1', 'field_2']
     *    :multiply, [12, 'monthly_total_field']
     *
     * @param {Array<Numeric>} list - a list of Numeric values to multiply
     * @returns {Numeric} the product of the list
     */
    multiply: list => {
      list = this.normalizeOperands(list)

      if (list.length == 0) {
        return 0
      } else {
        return list.reduce((a, b) => a * b)
      }
    },
    /**
     * Divide using a list containing the numerator and denominator.
     *
     * Examples:
     *    :divide, [6, 2]
     *    :divide, ['field_1', 'field_2']
     *    :divide, ['yearly_total_field', 12]
     *
     * @param {Array<Numeric>} list - a list containing a numerator and denominator for division
     * @returns {Numeric} the quotient of the division operation
     */
    divide: list => {
      if (list.length > 2) {
        throw 'Invalid arguments. Division takes a list of [numerator, denominator]'
      }
      list = this.normalizeOperands(list)
      let [numerator, denominator] = list
      return numerator / denominator
    },
    /**
     * Returns the maximum value in the list.
     *
     * Examples:
     *    :max, [5, 2]
     *    :max, ['field_1', 'field_2']
     *    :max, [12, 'monthly_total_field']
     *
     * @param {Array<Numeric>} list - a list of Numeric values to get the maxiumum of
     * @returns {Numeric} the maximum value in the list
     */
    max: list => {
      list = this.normalizeOperands(list)
      return Math.max(...list)
    },
    /**
     * Returns the minimum value in the list.
     *
     * Examples:
     *    :min, [5, 2]
     *    :min, ['field_1', 'field_2']
     *    :min, [12, 'monthly_total_field']
     *
     * @param {Array<Numeric>} list - a list of Numeric values to get the minimum of
     * @returns {Numeric} the minimum value in the list
     */
    min: list => {
      list = this.normalizeOperands(list)
      return Math.min(...list)
    },
    /**
     * Filter a list of SuperForm fields by a value or list of values.
     *
     * Example:
     *   :filter, ['Foo', 'field_1', 'field_2']
     *   :filter, [['Foo', 'Bar], 'field_1', 'field_2']
     *
     * @param {String|Array<String>} filterValueOrArray - a value or list of values to filter fields by.
     * @returns {Array<Element>} returns the elements that match the filter values.
     */
    filter: ([filterValueOrArray, ...list]) => {
      let values = Array.from(filterValueOrArray)

      let filteredValues = list.flatMap(field => {
        let elements = this.getElementsByName(field)
        return elements.filter(el => values.includes(el.labels[0].textContent) && el.checked).map(this.getElementValue)
      })

      return filteredValues
    }
  }

  /**
   * Recursively evaluate the expression by implementing Greenspun's tenth rule.
   *
   *  See:
   *    https://en.wikipedia.org/wiki/Greenspun%27s_tenth_rule
   *    https://en.wikipedia.org/wiki/Lisp_(programming_language)
   *
   * @param {Array} formula - a SuperForm formula S-Expression
   * @returns {Numeric|Array} A SuperForm formula or Numeric result
   */
  evalFormula(formula) {
    if (!Array.isArray(formula)) {
      return formula
    }

    let [operation, ...operands] = formula
    let operationFn = this.OPERATIONS[operation]
    if (operationFn) {
      let operandValues = operands.flatMap(this.evalFormula.bind(this))
      return operationFn(operandValues)
    } else {
      return formula
    }
  }

  /**
   * Lookup elements by superform input name. Caches results so we don't heat the planet searching the DOM.
   *
   * @param {String} name - a SuperForm field name
   * @returns {Array} a list of elements that represent the SuperForm field
   */
  getElementsByName(name) {
    const inputName = name.toString().startsWith('[') ? `${this.formPrefix}${name}` : `${this.formPrefix}[${name}]`
    let elements = [inputName, inputName + '[]'].flatMap(name => {
      return Array.from(document.getElementsByName(name)).filter(el => el.type !== 'hidden')
    })
    return elements
  }

  /**
   * Get a value for a SuperForm field name.
   * @param {Element} el - the input element to get a value from.
   * @returns {Numeric} a numeric value for a field.
   */
  getElementValue(el) {
    if (el) {
      if (el.dataset?.scoreMetadata) {
        let metadata = JSON.parse(el.dataset.scoreMetadata)
        let scoreOption = metadata.find(option => option.value.toString() === el.value)
        return scoreOption ? scoreOption.score : 0
      } else if (MULTIPLE_INPUT_ELEMENTS.includes(el.type)) {
        if ([TRUE_STRING, FALSE_STRING].includes(el.value)) {
          return el.checked && el.value === TRUE_STRING ? 1 : 0
        } else if (typeof el.value === 'string') {
          return el.checked ? 1 : 0
        } else {
          return NormalizedNumber(el.value)
        }
      } else {
        if (el.dataset?.score && el.value.trim() !== '') {
          return Number(el.dataset.score)
        } else {
          let numeric = NormalizedNumber(el.value)
          if (Number.isFinite(numeric)) {
            return numeric
          } else {
            return el.value === TRUE_STRING ? 1 : 0
          }
        }
      }
    }
  }

  /**
   * Takes a mixed list of SuperForm field names, DOM Elements and numeric values and returns all values.
   *
   * @param {Array} operands - a list of numeric values, DOM Elements and SuperForm field names
   * @returns {Array} a list of values for calculation
   */
  normalizeOperands(operands) {
    let normalizedOperands = Array.isArray(operands) ? operands : [operands]
    return normalizedOperands.flatMap(operand => {
      let numeric = NormalizedNumber(operand)
      if (Number.isFinite(numeric)) {
        // If the argument is numeric, just return it.
        return numeric
      } else if (typeof operand === 'string') {
        // If the operand is a field name, look up the elements.
        let elements = this.getElementsByName(operand)

        if (elements.length > 1 && MULTIPLE_INPUT_ELEMENTS.includes(elements[0].type)) {
          // If there are many elements for the input, filter to the set of elements that are checked
          elements = elements.filter(el => el.checked)
        } else if (elements.length > 1 && !ARRAY_INPUT_NAME_REGEX.test(operand)) {
          // For a duplicate of the same field, take the first one, they should always have the same value.
          elements = elements.slice(0, 1)
        } else {
          // This is probably an ARRAY_INPUT_NAME_REGEX from a BasicList
        }

        return Array.from(elements).map(this.getElementValue)
      } else {
        return this.getElementValue(operand)
      }
    })
  }
}
