import { Controller } from '@hotwired/stimulus'
import { RRule, Weekday } from 'rrule'
import moment from 'moment'
import { map, identity, times } from 'ramda'
import flatpickr from 'flatpickr'

export default class extends Controller {
  static targets = [
    'endAt',
    'startAt',
    'type',
    'weekdays',
    'days',
    'timeslots',
    'rule',
    'monthly',
    'monthlyBySetPos',
    'monthlyByDay'
  ]

  static values = {
    formPrefix: String,
    hasTimeslots: Boolean
  }

  connect() {
    // Bind other functions to `this`
    this.updateScheduleType = this.updateScheduleType.bind(this)
    this.updateMonthlySchedule = this.updateMonthlySchedule.bind(this)
    this.updateDaySchedule = this.updateDaySchedule.bind(this)

    // Initialize form controls
    this.initializeInputs()

    this.typeTarget.addEventListener('change', this.updateScheduleType)

    this.daysTargets.forEach(weekdayInput => {
      weekdayInput.addEventListener('change', this.updateDaySchedule)
    })

    this.monthlyBySetPosTarget.addEventListener('change', this.updateMonthlySchedule)
    this.monthlyByDayTarget.addEventListener('change', this.updateMonthlySchedule)
  }

  disconnect() {
    this.typeTarget.removeEventListener('change', this.updateScheduleType)

    this.daysTargets.forEach(weekdayInput => {
      weekdayInput.removeEventListener('change', this.updateDaySchedule)
    })

    this.monthlyBySetPosTarget.removeEventListener('change', this.updateMonthlySchedule)
    this.monthlyByDayTarget.removeEventListener('change', this.updateMonthlySchedule)
  }

  // Setup the control state from the value of the hidden RRule input.
  initializeInputs() {
    let scheduleTypeOptions = Array.from(this.typeTarget.children)

    let hasTimeslots = this.hasTimeslotsValue;

    if (this.ruleTarget.value === '' && !hasTimeslots) {
      // schedule type selected option will be 'Weekly on...' by default
      scheduleTypeOptions.find(option => option.label === 'Weekly on...').selected = true
    } else if (this.ruleTarget.value === ''){
      // when schedule type selected option 'Custom', end at field will be hidden
      scheduleTypeOptions.find(option => option.label === 'Custom').selected = true
      this.hideEndAtStartAtField()
    } else {
      let currentRRule = RRule.fromString(this.ruleTarget.value)

      let fixedScheduleOption = scheduleTypeOptions.find(option => {
        let optionRRule = RRule.fromString(option.value)

        return (
          JSON.stringify(currentRRule.origOptions.byweekday) === JSON.stringify(optionRRule.origOptions.byweekday) &&
          currentRRule.origOptions.freq === optionRRule.origOptions.freq &&
          currentRRule.origOptions.interval === optionRRule.origOptions.interval
        )
      })

      if (fixedScheduleOption) {
        fixedScheduleOption.selected = true
      } else {
        let monthlyOrByDayOption = scheduleTypeOptions.find(option => {
          let optionRRule = RRule.fromString(option.value)

          return (
            optionRRule.origOptions.byweekday === undefined &&
            currentRRule.origOptions.freq === optionRRule.origOptions.freq &&
            currentRRule.origOptions.interval === optionRRule.origOptions.interval
          )
        })

        if (monthlyOrByDayOption) {
          monthlyOrByDayOption.selected = true
          if (monthlyOrByDayOption.text === 'Monthly') {
            this.initializeMonthlyInputs()
          } else {
            let selectedDays = currentRRule.origOptions.byweekday.map(day => day.toString())
            Array.from(this.daysTargets).forEach(element => (element.checked = selectedDays.includes(element.value)))
          }
        }
      }
    }

    this.updateScheduleType()
  }

  initializeMonthlyInputs() {
    let currentRRule = RRule.fromString(this.ruleTarget.value)

    Array.from(this.monthlyBySetPosTarget.options).forEach(option => {
      if (option.value === `BYSETPOS=${currentRRule.options.bysetpos.join()}`) option.selected = true
    })

    Array.from(this.monthlyByDayTarget.options).forEach(option => {
      let weekday = new Weekday(currentRRule.options.byweekday.join())
      if (option.value === `BYDAY=${weekday.toString()}`) option.selected = true
    })
  }

  setRRule(rrule) {
    console.log('RRule:', rrule)
    this.ruleTarget.value = rrule
  }

  updateMonthlySchedule() {
    this.setRRule(
      this.buildRRule(this.typeTarget.value, this.monthlyBySetPosTarget.value, this.monthlyByDayTarget.value)
    )
  }

  updateDaySchedule() {
    let selectedDaysElements = Array.from(this.daysTargets).filter(element => element.checked === true)
    let selectedDays = selectedDaysElements.map(el => el.value)
    if (selectedDays.length > 0) {
      this.setRRule(this.buildRRule(this.typeTarget.value, `BYDAY=${selectedDays.join(',')}`))
    } else {
      this.setRRule(this.buildRRule(this.typeTarget.value))
    }
  }

  updateScheduleType() {
    this.element.querySelectorAll('.rrule-element').forEach(el => el.classList.add('d-none'))

    let currentSelection = this.typeTarget.options[this.typeTarget.options.selectedIndex]
    if (currentSelection?.label === 'Custom') {
      this.timeslotsTarget.classList.remove('d-none')
      this.hideEndAtStartAtField()
      this.endAtTarget.classList.add('d-none')
      this.setRRule('')
    } else if (currentSelection?.label === 'Monthly') {
      this.updateMonthlySchedule()
      this.monthlyTarget.classList.remove('d-none')
      this.revealStartAtEndAtField()
    } else {
      this.revealStartAtEndAtField()
      let rule = RRule.fromString(this.typeTarget.value)
      if (rule.origOptions.byweekday === undefined) {
        this.updateDaySchedule()
        this.weekdaysTarget.classList.remove('d-none')
      } else {
        this.setRRule(this.buildRRule(this.typeTarget.value))
      }
    }
  }

  hideEndAtStartAtField() {
    if (this.hasEndAtTarget && !this.endAtTarget.classList.contains('d-none')) {
      this.endAtTarget.classList.add('d-none')
      this.endAtTarget.querySelectorAll('input').forEach(input => {
        input.value = ''
      })
    }
    if (this.hasStartAtTarget && !this.startAtTarget.classList.contains('d-none')) {
      this.startAtTarget.classList.add('d-none')
      this.startAtTarget.querySelectorAll('input').forEach(input => {
        input.value = ''
      })
    }
  }

  revealStartAtEndAtField() {
    if (this.endAtTarget.classList.contains('d-none')) {
      this.endAtTarget.classList.remove('d-none')
    }
    if (this.startAtTarget.classList.contains('d-none')) {
      this.startAtTarget.classList.remove('d-none')
    }
  }

  buildRRule = (...segments) => `RRULE:${segments.join(';')}`
}
