import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = {
    message: String,
    reload: Boolean
  }

  connect() {
    document.dispatchEvent(new CustomEvent('toast', { detail: { message: this.messageValue } }))

    const waitTimeBeforeLeavePage = 2000 // wait for the flash message to appear

    setTimeout(() => {
      history.back()
      this.reloadData()
    }, waitTimeBeforeLeavePage)
  }

  reloadData() {
    const waitTimeBeforeRefreshPage = 200 // wait the current page to refresh before going back

    if (this.reloadValue) {
      setTimeout(() => {
        window.location.reload();
      }, waitTimeBeforeRefreshPage)
    }
  }
}
