import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect() {
    this.updateCaseValue = this.updateCaseValue.bind(this)
    let source = this.data.get('source')
    this.sourceEl = document.getElementsByName(source)[0]
    this.when = JSON.parse(this.data.get('when'))
    if (this.sourceEl) {
      this.sourceEl.addEventListener('change', this.updateCaseValue)
      this.updateCaseValue()
    }
  }

  disconnect() {}

  updateCaseValue() {
    if (!this.when) {
      return
    }

    let sourceValue = this.sourceEl.value
    let found = this.when.find(({ min, max, value }) => {
      if (sourceValue >= min && sourceValue <= max) {
        return value
      }
    })

    if (!found) {
      return
    }

    let { value } = found

    let element = this.element
    if (element.type === 'checkbox') {
      console.log('Cases do not work with the checkbox data type')
    } else if (element.type === 'radio') {
      this.toggleRadioButton(element, value)
    } else {
      element.setAttribute('readonly', true)
      element.value = value
    }
  }

  toggleRadioButton(element, value) {
    if (element.value !== value) {
      element.setAttribute('disabled', true)
    } else {
      element.checked = true
      element.removeAttribute('disabled')
    }
  }
}
